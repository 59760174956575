import { Fragment } from "react";

import TopBar from "./TopBar";
import MainContainer from "./MainContainer";
import Footer from "./Footer";
import ScrollTop from "../ScrollTop/ScrollTop";

const Layout = props => {
  return (
    <Fragment>
      <ScrollTop />
      <TopBar />
      <MainContainer>{props.children}</MainContainer>
      <Footer />
    </Fragment>
  );
};

export default Layout;
