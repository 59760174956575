import { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import TopBarLogo from "./TopBarLogo";
import Burger from "../Burger/Burger";
import BurgerMenu from "../BurgerMenu/BurgerMenu";

import AuthContext from "../../store/auth-context";

import { _TOOLBAR_SETTINGS } from "../../settings/settings";

import classes from "./TopBar.module.css";

const TopBar = () => {
  const [open, setOpen] = useState(false);

  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;
  const isAdmin = authCtx.isAdmin;

  const logoutHandler = () => {
    setOpen(false);
    authCtx.logout();
    history.replace("/");
  };

  const clickHandler = () => {
    setOpen(!open);
  };

  return (
    <header className={classes.header}>
      <Link to="/">
        <TopBarLogo />
      </Link>
      <nav>
        <Burger onClick={clickHandler} open={open} />
        {open && (
          <BurgerMenu>
            <ul>
              {_TOOLBAR_SETTINGS.showAcagru && (
                <li>
                  <a
                    href="https://escuteiros-portugal.org/acagru"
                    className={classes.headerlink}
                    target={"_blank"}
                    rel="noreferrer"
                    onClick={clickHandler}
                  >
                    ACAGRU
                  </a>
                </li>
              )}
              {_TOOLBAR_SETTINGS.showProfile && isLoggedIn && (
                <li>
                  <Link
                    to="/profile"
                    className={classes.headerlink}
                    onClick={clickHandler}
                  >
                    Perfil
                  </Link>
                </li>
              )}
              {isLoggedIn && isAdmin && (
                <li>
                  <Link
                    to="/admin"
                    className={classes.headerlink}
                    onClick={clickHandler}
                  >
                    Admin
                  </Link>
                </li>
              )}
              {isLoggedIn && (
                <li>
                  <button onClick={logoutHandler}>Logout</button>
                </li>
              )}
              {_TOOLBAR_SETTINGS.showLogin && !isLoggedIn && (
                <li>
                  <Link
                    to="/auth"
                    className={classes.headerlink}
                    onClick={clickHandler}
                  >
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </BurgerMenu>
        )}
        <ul className={classes.menu}>
          {_TOOLBAR_SETTINGS.showAcagru && (
            <li>
              <a
                href="https://escuteiros-portugal.org/acagru"
                className={classes.headerlink}
                target={"_blank"}
                rel="noreferrer"
              >
                ACAGRU
              </a>
            </li>
          )}
          {_TOOLBAR_SETTINGS.showProfile && isLoggedIn && (
            <li>
              <Link to="/profile" className={classes.headerlink}>
                Perfil
              </Link>
            </li>
          )}
          {isLoggedIn && isAdmin && (
            <li>
              <Link to="/admin" className={classes.headerlink}>
                Admin
              </Link>
            </li>
          )}
          {isLoggedIn && (
            <li>
              <button onClick={logoutHandler}>Logout</button>
            </li>
          )}
          {_TOOLBAR_SETTINGS.showLogin && !isLoggedIn && (
            <li>
              <Link to="/auth" className={classes.headerlink}>
                Login
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
};

export default TopBar;
