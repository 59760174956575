import { ReactComponent as Logo } from "../../assets/logo.svg";
import { ReactComponent as CodigoBraile } from "../../assets/bv.svg";
import { ReactComponent as BraileFalso } from "../../assets/cb.svg";
import { ReactComponent as ContactForm } from "../../assets/contactform.svg";
import { ReactComponent as CodigoTransposto } from "../../assets/ct.svg";
import { ReactComponent as Encryption } from "../../assets/encryption.svg";
import { ReactComponent as MorseCode } from "../../assets/mc.svg";
import { ReactComponent as MorsePeaks } from "../../assets/pm.svg";
import { ReactComponent as RoyalMail } from "../../assets/rm4scc.svg";

const SVGIcon = props => {
  const IconMapping = {
    Logo,
    CodigoBraile,
    BraileFalso,
    ContactForm,
    CodigoTransposto,
    Encryption,
    MorseCode,
    MorsePeaks,
    RoyalMail,
  };

  const { icon } = props;
  const TheIcon = IconMapping[icon];

  return <TheIcon {...props} />;
};

export default SVGIcon;
