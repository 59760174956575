import SVGIcon from "../SVGIcon/SVGIcon";
import classes from "./TopBarLogo.module.css";

const TopBarLogo = () => {
  return (
    <div className={classes.headerLogo}>
      <SVGIcon icon="Logo" className={classes.headerLogoImage} />
      <span className={classes.headerLogoText}>Escuteiros de Portugal</span>
    </div>
  );
};

export default TopBarLogo;
