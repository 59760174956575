import React, { useState, useEffect, useCallback } from "react";

let logoutTimer = null;

const timeLimit = 14400000; // 4 hours

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  isAdmin: false,
  login: (idToken, expiresIn, isadmin) => {},
  logout: () => {},
});

const calculateRemainingTime = expirationTime => {
  const currentTime = new Date().getTime();
  const remainingTime = expirationTime - currentTime;
  return remainingTime;
};

const retrieveStoreToken = () => {
  const storedToken = localStorage.getItem("token");
  const storedExpirationTime = parseInt(localStorage.getItem("expirationTime"));
  const storedAdminFlag = parseInt(localStorage.getItem("adm"));

  const remainingTime = calculateRemainingTime(storedExpirationTime);

  if (remainingTime <= timeLimit) {
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    return null;
  }

  let tokenData = null;
  if (storedToken && storedExpirationTime && storedAdminFlag) {
    tokenData = {
      token: storedToken,
      duration: remainingTime,
      adm: storedAdminFlag,
    };
  }

  return tokenData;
};

const parseAdmin = value => {
  let output = false;

  if (value === 1) {
    output = true;
  }
  return output;
};

export const AuthContextProvider = props => {
  const tokenData = retrieveStoreToken();

  let initialToken = null;
  let initialAdm = "0";
  if (tokenData) {
    initialToken = tokenData.token;
    initialAdm = tokenData.adm;
  }
  const [token, setToken] = useState(initialToken);
  const [adm, setAdm] = useState(initialAdm);

  const userIsLoggedIn = !!token;
  const userIsAdmin = parseAdmin(adm);

  const logoutHandler = useCallback(() => {
    setAdm(null);
    setToken(null);
    localStorage.removeItem("token");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("adm");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (idToken, expiresIn, isAdmin) => {
    const expirationTime = new Date(
      new Date().getTime() + +expiresIn * 1000
    ).getTime();

    setToken(idToken);
    setAdm(isAdmin);
    localStorage.setItem("token", idToken);
    localStorage.setItem("expirationTime", expirationTime.toString());
    localStorage.setItem("adm", isAdmin);

    const remainingTime = calculateRemainingTime(expirationTime);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    isAdmin: userIsAdmin,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
