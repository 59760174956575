import { useEffect, useState } from "react";

import classes from "./Burger.module.css";

const Burger = props => {
  const [class1, setclass1] = useState("");
  const [class2, setClass2] = useState("");
  const [class3, setClass3] = useState("");

  const open = props.open;

  const clickHandler = () => {
    props.onClick(!open);
  };

  useEffect(() => {
    if (open) {
      setclass1(classes.close1);
      setClass2(classes.close2);
      setClass3(classes.close3);
    } else {
      setclass1("");
      setClass2("");
      setClass3("");
    }
  }, [open]);

  return (
    <div className={classes.burger} onClick={clickHandler}>
      <div className={class1}></div>
      <div className={class2}></div>
      <div className={class3}></div>
    </div>
  );
};

export default Burger;
