import React, { useContext, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Layout from "./components/Layout/Layout";
import LoadingSpinner from "./components/UI/LoadingSpinner";
import AuthContext from "./store/auth-context";
// import RedirectACAGRU from "./components/RedirectACAGRU/RedirectACAGRU";

const HomePage = React.lazy(() => import("./pages/HomePage"));
const AuthPage = React.lazy(() => import("./pages/AuthPage"));
const ProfilePage = React.lazy(() => import("./pages/ProfilePage/ProfilePage"));
const AdminPage = React.lazy(() => import("./pages/AdminPage/AdminPage"));
const AdminNewAccount = React.lazy(() =>
  import("./pages/AdminPage/AdminnewAccount")
);
const PrivacyPage = React.lazy(() => import("./pages/PrivacyPage"));
const Contact = React.lazy(() => import("./pages/Contact"));
const CodesPage = React.lazy(() => import("./pages/CodesPage"));
const PicosMorse = React.lazy(() => import("./pages/PicosMorse"));
const Transposto = React.lazy(() => import("./pages/Transposto"));
const BraileFalso = React.lazy(() => import("./pages/BraileFalso"));
const BraileVerdadeiro = React.lazy(() => import("./pages/BraileVerdadeiro"));
const CodigoData = React.lazy(() => import("./pages/CodigoData"));
const Morse = React.lazy(() => import("./pages/Morse"));
const Numeral = React.lazy(() => import("./pages/Numeral"));
const RomanoArabe = React.lazy(() => import("./pages/RomanoArabe"));
const Angular = React.lazy(() => import("./pages/Angular"));
const RM4SCC = React.lazy(() => import("./pages/RM4SCC"));

function App() {
  const authCtx = useContext(AuthContext);
  return (
    <Layout>
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>

          {!authCtx.isLoggedIn && (
            <Route path="/auth" exact>
              <AuthPage />
            </Route>
          )}
          {authCtx.isLoggedIn && (
            <Route path="/profile" exact>
              <ProfilePage />
            </Route>
          )}
          {authCtx.isLoggedIn && authCtx.isAdmin && (
            <Route path="/admin" exact>
              <AdminPage />
            </Route>
          )}
          {authCtx.isLoggedIn && authCtx.isAdmin && (
            <Route path="/admin-new-account" exact>
              <AdminNewAccount />
            </Route>
          )}

          <Route path="/privacidade" exact>
            <PrivacyPage />
          </Route>

          <Route path="/contacto" exact>
            <Contact />
          </Route>

          <Route path="/codigos" exact>
            <CodesPage />
          </Route>

          <Route path="/codigos/picos-de-morse" exact>
            <PicosMorse />
          </Route>

          <Route path="/codigos/transposto" exact>
            <Transposto />
          </Route>

          <Route path="/codigos/braile-falso" exact>
            <BraileFalso />
          </Route>

          <Route path="/codigos/braile-verdadeiro" exact>
            <BraileVerdadeiro />
          </Route>

          <Route path="/codigos/codigo-data" exact>
            <CodigoData />
          </Route>

          <Route path="/codigos/codigo-morse" exact>
            <Morse />
          </Route>

          <Route path="/codigos/alfabeto-numeral" exact>
            <Numeral />
          </Route>

          <Route path="/codigos/romano-arabe" exact>
            <RomanoArabe />
          </Route>

          <Route path="/codigos/codigo-angular" exact>
            <Angular />
          </Route>

          <Route path="/codigos/codigo-royal-mail" exact>
            <RM4SCC />
          </Route>

          {/* <Route path="/acagru" exact>
              <RedirectACAGRU />
            </Route> */}

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    </Layout>
  );
}

export default App;
