import { Link } from "react-router-dom";
import classes from "./Footer.module.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <div>
        <a
          href="https://creativecommons.org/licenses/by-nc/4.0/deed.pt"
          target="_blank"
          rel="noreferrer"
        >
          CC BY-NC 4.0
        </a>
        &copy; {currentYear} Escuteiros de Portugal - Direitos Reservados
      </div>
      <Link to="/privacidade">Política de Privacidade</Link>
    </footer>
  );
};

export default Footer;
