import React, { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";

import classes from "./ScrollTop.module.css";

const ScrollTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const faClasses = [classes.iconposition, classes.iconstyle].join(" ");

  return (
    <div className={classes.scrollTop}>
      {" "}
      {showTopBtn && <FaAngleUp className={faClasses} onClick={goToTop} />}{" "}
    </div>
  );
};

export default ScrollTop;
